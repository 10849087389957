import React from 'react'
import {Link} from 'gatsby'

const SmartLink = ({
  _type,
  title,
  url,
  product,
  slug,
  openInNewWindow,
  productName,
  pageName,
  collectionName,
  children,
  ...props
}) => {
  if (url[0] === '/') {
    return (
      <Link
        {...props}
        title={title}
        target={openInNewWindow ? '_blank' : null}
        rel={openInNewWindow ? 'noopener noreferrer' : null}
        to={`${url}`}
      >
        {children || title}
      </Link>
    )
  } else {
    return (
      <a
        {...props}
        title={title}
        target={openInNewWindow ? '_blank' : null}
        rel={openInNewWindow ? 'noopener noreferrer' : null}
        href={url}
      >
        {children || title}
      </a>
    )
  }
}

export default SmartLink
