require('unfetch');

const React = require('react');
// const Sentry = require('@sentry/browser');
const Layout = require('./src/components/Layout').default;
const routeDuration = require('./src/components/Layout').TRANSITION_DURATION;
// const Analytics = require('./src/components/Analytics').initialize;

// Config Sentry
// Sentry.init({
// 	dsn: process.env.GATSBY_SENTRY_DSN,
// 	// This environment variable is provided via Webpack configuration
// 	// @see ./gatsby-node.js
// 	release: process.env.GITCOMMIT,
// });

// Setup analytics
// app.analytics = Analytics({
// 	googleAnalyticsPropertyId: process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY,
// 	googleLinkerDomains: [
// 		'personalplants.com',
// 		'personal-plants.netlify.app',
// 		'localhost:8000',
// 	],
// });

// exports.onRouteUpdate = ({location, previousLocation, ...props}) => {
// 	// Track page views
// 	app.analytics.pageview(location);
// };

// Wrap every page with the main layout
exports.wrapPageElement = ({element, props}) => (
	<Layout {...props}>{element}</Layout>
);

// Wrap application with provider
// exports.wrapRootElement = ({element, props}) => (
// 	<Provider
// 		{...props}
// 		store={app.store}>
// 		{element}
// 	</Provider>
// );

// Delay updating scroll

exports.shouldUpdateScroll = ({
	prevRouterProps,
	routerProps,
}) => {
	if(!prevRouterProps) return
	if (routerProps.location.pathname !== prevRouterProps.location.pathname) {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, routeDuration);
	}

	return false;
};

// Site Credit
console.log(`
Site Credit
===========
Development : https://patrickmccarthy.lol
`);